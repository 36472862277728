import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'
import { mq } from '../../../styles/vars/media-queries.style'
import { colors } from '../../../styles/vars/colors.style'
import { grid } from '../../../styles/vars/grid.style'
import { getClampValue } from '../../../styles/utils/conversion.style'
import { GridItemMain } from '../../GridItem/index.style'

export const BenefitsMain = styled.div`
  text-align: center;
`

export const BenefitsGrid = styled.div`
  ${mq.deskL} {
    margin-left: auto;
    margin-right: auto;
    max-width: 148rem;
  }
`

export const BenefitsGridItem = styled.div`
  position: relative;

  :before {
    background-color: ${colors.midGrey};
    content: '';
    display: none;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    right: calc(-1 * ${getClampValue(grid.gap.min / 2, grid.gap.max / 2)});
    top: 0;
    transform: scaleY(${props => (props.inView ? 1 : 0)});
    transition: transform 0.5s ${easings.inOut.default};
    transition-delay: ${props => `${props.delay}s`};
    transform-origin: 0% 100%;
    width: 0.1rem;
  }

  ${mq.tabletPMax} {
    ${GridItemMain}:nth-child(odd) > & {
      :before {
        display: block;
      }
    }
  }

  ${mq.tabletPToDesk} {
    :before {
      display: block;
    }

    ${GridItemMain}:nth-child(3n + 3) > & {
      :before {
        display: none;
      }
    }
  }

  ${mq.desk} {
    :before {
      display: block;
    }

    div:last-child > & {
      :before {
        display: none;
      }
    }
  }
`

export const BenefitsIcon = styled.div`
  height: 12.2rem;
  padding: 0 20%;

  ${mq.tabletL} {
    padding: 0 30%;
  }
  ${mq.desk} {
    padding: 0 25%;
  }
  ${mq.deskL} {
    padding: 0 22%;
  }
`

export const BenefitsDescription = styled.span`
  display: inline-block;
  padding: 0 12%;

  ${mq.deskL} {
    padding: 0 15%;
  }
`
