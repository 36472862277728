import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import Hero from '../components/Compare/Hero'
import Benefits from '../components/Compare/Benefits'
import ImageContentGrid from '../components/ImageContentGrid'
import Comparison from '../components/Compare/Comparison'
import PageNav from '../components/PageNav'
// import CutCompare from '../components/Compare/CutCompare'

const ComparePage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    heroTitle: { heroTitle },
    heroText,
    heroAnchorLinkLabel,
    // traditionalCutLabel,
    // firstFitCutLabel,
    benefitsTitle,
    benefitsIcons,
    workflowTitle,
    workflowText,
    workflowImage,
    timeTitle,
    timeText,
    timeImage,
    preparationTitle,
    preparationText,
    preparationImage,
    cutTitle,
    cutText,
    cutImage,
    // accuracyTitle,
    // accuracyText,
    // accuracyImage,
    comparisonTableTitle,
    comparisonTable,
    pageNavigation,
  } = data.contentfulCompare

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        pathname={data.site.siteMetadata.routes.compare}
      />
      <Hero title={heroTitle} text={heroText} label={heroAnchorLinkLabel} />
      {/* <CutCompare labelA={traditionalCutLabel} labelB={firstFitCutLabel} /> */}
      <Benefits title={benefitsTitle} icons={benefitsIcons} />
      <ImageContentGrid
        title={workflowTitle}
        text={workflowText}
        image={workflowImage}
      />
      <ImageContentGrid
        title={timeTitle}
        text={timeText}
        image={timeImage}
        flip
      />
      <ImageContentGrid
        title={preparationTitle}
        text={preparationText}
        image={preparationImage}
      />
      <ImageContentGrid title={cutTitle} text={cutText} image={cutImage} flip />
      {/* <ImageContentGrid
        title={accuracyTitle}
        text={accuracyText}
        image={accuracyImage}
      /> */}
      <Comparison
        title={comparisonTableTitle}
        data={comparisonTable.table.tableData}
      />
      <PageNav
        title={pageNavigation.title}
        linkSlug={pageNavigation.pageLink.slug}
        linkText={pageNavigation.linkText}
        image={pageNavigation.image}
      />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        routes {
          compare
        }
      }
    }
    contentfulCompare {
      seoTitle
      seoDescription {
        seoDescription
      }
      heroTitle {
        heroTitle
      }
      heroText {
        raw
      }
      heroAnchorLinkLabel
      traditionalCutLabel
      firstFitCutLabel
      benefitsTitle
      benefitsIcons {
        gatsbyImageData
        description
      }
      workflowTitle
      workflowText {
        raw
      }
      workflowImage {
        leftText
        leftImage {
          gatsbyImageData
          description
        }
        rightText
        rightImage {
          gatsbyImageData
          description
        }
      }
      timeTitle
      timeText {
        raw
      }
      timeImage {
        leftText
        leftImage {
          gatsbyImageData
          description
        }
        rightText
        rightImage {
          gatsbyImageData
          description
        }
      }
      preparationTitle
      preparationText {
        raw
      }
      preparationImage {
        leftText
        leftImage {
          gatsbyImageData
          description
        }
        rightText
        rightImage {
          gatsbyImageData
          description
        }
      }
      cutTitle
      cutText {
        raw
      }
      cutImage {
        leftText
        leftImage {
          gatsbyImageData
          description
        }
        rightText
        rightImage {
          gatsbyImageData
          description
        }
      }
      # accuracyTitle
      # accuracyText {
      #   raw
      # }
      # accuracyImage {
      #   leftText
      #   leftImage {
      #     gatsbyImageData
      #     description
      #   }
      #   rightText
      #   rightImage {
      #     gatsbyImageData
      #     description
      #   }
      # }
      comparisonTableTitle
      comparisonTable {
        table {
          tableData
        }
      }
      pageNavigation {
        ...PageLink
      }
    }
  }
`

export default ComparePage
