import React from 'react'
import PropTypes from 'prop-types'
import { HeroMain, HeroText, HeroLink, HeroBorder } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import AnimateSplitText from '../../animation/AnimateSplitText'
import RichText from '../../RichText'
import { Heading1, TextBodySmall } from '../../TextStyles'
import AnchorLink from '../../AnchorLink'
import IconDownArrow from '../../svgs/IconDownArrow'
import ScrollSection from '../../ScrollSection'
import AnimateSlideIn from '../../animation/AnimateSlideIn'

const Hero = ({ title, text, label }) => (
  <ScrollSection>
    <HeroMain>
      <Container>
        <Spacer size={[116, 275]} />

        <Grid>
          <GridItem tabletL={11} desk={8} deskStart={2}>
            <Heading1 as="h2">
              <AnimateSplitText heading>{title}</AnimateSplitText>
            </Heading1>

            <Spacer size={32} />

            <HeroText>
              <RichText content={text} delay={0.3} paragraphSize="regular" />
            </HeroText>

            <Spacer size={[22, 40]} />

            <HeroLink>
              <TextBodySmall>
                <AnchorLink target="cutCompare">
                  <AnimateSplitText delay={0.6}>{label}</AnimateSplitText>

                  <AnimateSlideIn delay={1}>
                    <IconDownArrow responsive />
                  </AnimateSlideIn>
                </AnchorLink>
              </TextBodySmall>
            </HeroLink>
          </GridItem>
        </Grid>

        <Spacer size={[32, 108]} />
      </Container>

      <AnimateSlideIn direction="left" delay={0.2}>
        <HeroBorder />
      </AnimateSlideIn>
    </HeroMain>
  </ScrollSection>
)

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
}

export default Hero
