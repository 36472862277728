import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const IconTick = ({
  width = 17,
  height = 12,
  fill = colors.blue,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 12"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path d="M1 5.5l5 5L15.5 1" stroke={fill} strokeWidth="2" />
    </svg>
  )
}

export default IconTick
