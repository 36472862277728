import React from 'react'
import PropTypes from 'prop-types'
import { ComparisonMain } from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import Table from '../../Table'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import { Heading2 } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { colors } from '../../../styles/vars/colors.style'

const Comparison = ({ title, data }) => {
  return (
    <ScrollSection>
      <ComparisonMain>
        <Container>
          <Grid>
            <GridItem desk={10} deskStart={2}>
              <Heading2 color={colors.blue}>
                <AnimateSplitText type="lines,words">{title}</AnimateSplitText>
              </Heading2>

              <Spacer size={32} />

              <AnimateSlideIn>
                <Table data={data} />
              </AnimateSlideIn>
            </GridItem>
          </Grid>
          <Spacer size={[110, 208]} />
        </Container>
      </ComparisonMain>
    </ScrollSection>
  )
}

Comparison.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default Comparison
