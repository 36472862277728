import React from 'react'
import PropTypes from 'prop-types'
import { ImageContentMain, ImageContentText } from './index.style'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import RichText from '../RichText'
import ImageCompare from '../ImageCompare'
import { Heading2 } from '../TextStyles'
import ScrollSection from '../ScrollSection'
import AnimateSplitText from '../animation/AnimateSplitText'
import { colors } from '../../styles/vars/colors.style'

const ImageContentGrid = ({ title, text, image, flip }) => (
  <ScrollSection>
    <ImageContentMain>
      <Container allowOverflow>
        <Grid>
          <GridItem
            tabletL={5}
            tabletLAlign={'center'}
            tabletLStart={flip ? 8 : 1}
            desk={4}
            deskStart={flip ? 8 : 2}
          >
            <Heading2 color={colors.blue}>
              <AnimateSplitText type="lines,words">{title}</AnimateSplitText>
            </Heading2>

            <Spacer size={32} />

            <ImageContentText>
              <RichText content={text} paragraphSize="regular" />
            </ImageContentText>
          </GridItem>

          <GridItem
            tabletL={6}
            tabletLStart={flip ? 1 : 7}
            desk={5}
            deskStart={flip ? 2 : 7}
          >
            <ImageCompare {...image} />
          </GridItem>
        </Grid>
        <Spacer size={[80, 240]} />
      </Container>
    </ImageContentMain>
  </ScrollSection>
)

ImageContentGrid.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  flip: PropTypes.bool,
}

export default ImageContentGrid
