import React, { useRef, useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import {
  TableMain,
  TableScroller,
  TableScrollerInner,
  TableElementWrap,
  TableElement,
  TableHead,
  TableBody,
  TableRow,
  TableHeading,
  TableData,
  TableCross,
  TableTick,
  TableProgress,
  TableProgressBar,
  TableWrapper,
} from './index.style'
import IconCross from '../svgs/IconCross'
import IconTick from '../svgs/IconTick'

const replaceBool = (string, color) => {
  if (string === 'no')
    return (
      <TableCross>
        <IconCross responsive />
      </TableCross>
    )
  if (string === 'yes')
    return (
      <TableTick>
        <IconTick responsive />
      </TableTick>
    )
  return string
}

const Table = ({ data, delay, multiProduct }) => {
  const [ref, inView] = useInView()
  const [scrollable, setScrollable] = useState(false)
  const scrollArea = useRef()
  const scrollProgressBar = useRef()

  useEffect(() => {
    const configureProgressBar = () => {
      if (scrollArea.current)
        setScrollable(
          scrollArea.current.scrollWidth > scrollArea.current.clientWidth
        )
    }

    configureProgressBar()

    window.addEventListener('resize', configureProgressBar)

    return () => {
      window.removeEventListener('resize', configureProgressBar)
    }
  }, [])

  const updateScroll = () => {
    if (!scrollArea.current) return

    let maxScroll =
        scrollArea.current.scrollWidth - scrollArea.current.clientWidth,
      currentScroll = scrollArea.current.scrollLeft,
      scrollDecimal = currentScroll / maxScroll

    if (scrollProgressBar.current)
      scrollProgressBar.current.style.transform =
        'scaleX(' + scrollDecimal + ')'
  }

  return (
    <TableWrapper ref={ref} inView={inView} delay={delay}>
      <TableMain>
        <TableScroller onScroll={updateScroll} ref={scrollArea}>
          <TableScrollerInner>
            <TableElementWrap>
              <TableElement multiProduct={multiProduct}>
                <TableHead>
                  <TableRow>
                    {React.Children.toArray(
                      data[0].map((cell, cellIndex) => (
                        <TableHeading>{replaceBool(cell)}</TableHeading>
                      ))
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {React.Children.toArray(
                    data.map((row, rowIndex) => {
                      let rowClone = row.slice(0)
                      rowClone.shift()
                      let rowString = rowClone.join('')

                      if (rowIndex === 0) return false

                      return (
                        <TableRow>
                          {React.Children.toArray(
                            row.map((cell, cellIndex) => (
                              <TableData transparent={rowString.length === 0}>
                                {replaceBool(cell)}
                              </TableData>
                            ))
                          )}
                        </TableRow>
                      )
                    })
                  )}
                </TableBody>
              </TableElement>
            </TableElementWrap>
          </TableScrollerInner>
        </TableScroller>
      </TableMain>

      {scrollable && (
        <TableProgress>
          <TableProgressBar ref={scrollProgressBar}></TableProgressBar>
        </TableProgress>
      )}
    </TableWrapper>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  multiProduct: PropTypes.bool,
  delay: PropTypes.number,
}

Table.defaultProps = {
  multiProduct: false,
}

export default Table
