import React from 'react'
import PropTypes from 'prop-types'
import {
  AnchorLinkButton,
} from './index.style'
import { scrollTo } from '../../utils/utils'

const AnchorLink = ({ children, ariaLabel, target }) => (
  <AnchorLinkButton
    onClick={() => scrollTo(target)}
    aria-label={ariaLabel ? ariaLabel : `Scroll the page`}
  >
    {children}
  </AnchorLinkButton>
)

AnchorLink.propTypes = {
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  target: PropTypes.oneOfType([PropTypes.node, PropTypes.number]).isRequired
}

export default AnchorLink
