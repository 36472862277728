import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'

export const AnchorLinkButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: inherit;
  cursor: inherit;

  > span:last-child {
    display: inline-flex;
    ${clamp('width', 8, 10)}
    ${clamp('margin-left', 3, 5)}
  }
`
