import React from 'react'
import PropTypes from 'prop-types'
import {
  BenefitsMain,
  BenefitsGrid,
  BenefitsGridItem,
  BenefitsIcon,
  BenefitsDescription,
} from './index.style'
import { useInView } from 'react-intersection-observer'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import AnimateSlideIn from '../../animation/AnimateSlideIn'
import AnimateSplitText from '../../animation/AnimateSplitText'
import { Heading2, TextBodySmall } from '../../TextStyles'
import ScrollSection from '../../ScrollSection'
import { GatsbyImage } from 'gatsby-plugin-image'
import { colors } from '../../../styles/vars/colors.style'
import { useContext } from 'react'
import { StoreState } from '../../../Store'

const BenefitsItem = ({ index, icon }) => {
  const store = useContext(StoreState)
  const [ref, inView] = useInView()

  return (
    <BenefitsGridItem
      ref={ref}
      inView={!store.showTransitionMask && inView}
      delay={0.5 + index * 0.05}
    >
      <AnimateSlideIn delay={index * 0.05}>
        <BenefitsIcon>
          <GatsbyImage
            image={icon.gatsbyImageData}
            alt={icon.description}
            objectFit="contain"
          />
        </BenefitsIcon>
      </AnimateSlideIn>

      <Spacer size={[27, 46]} />

      <BenefitsDescription>
        <TextBodySmall>
          <AnimateSplitText delay={index * 0.05}>
            {icon.description}
          </AnimateSplitText>
        </TextBodySmall>
      </BenefitsDescription>
    </BenefitsGridItem>
  )
}

const Benefits = ({ title, icons }) => (
  <ScrollSection>
    <BenefitsMain>
      <Container>
        <Spacer size={[32, 108]} />

        <Heading2 color={colors.blue}>
          <AnimateSplitText type="lines,words">{title}</AnimateSplitText>
        </Heading2>

        <Spacer size={[45, 100]} />

        <BenefitsGrid>
          <Grid>
            {React.Children.toArray(
              icons.map((icon, iconIndex) => (
                <GridItem
                  tiny={6}
                  tabletP={4}
                  desk={2}
                  deskStart={iconIndex * 2 + 3}
                >
                  <BenefitsItem index={iconIndex} icon={icon} />
                </GridItem>
              ))
            )}
          </Grid>
        </BenefitsGrid>

        <Spacer size={[110, 344]} />
      </Container>
    </BenefitsMain>
  </ScrollSection>
)

Benefits.propTypes = {
  title: PropTypes.string.isRequired,
  icons: PropTypes.array.isRequired,
}

export default Benefits
