import styled, { css } from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { clamp } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import TableGradient from './images/table-gradient.jpg'
import { transparentize } from 'polished'
import { easings } from '../../styles/vars/easings.style'

export const TableWrapper = styled.div`
  position: relative;
  opacity: ${props => (props.inView ? 1 : 0)};
  transition: opacity ${easings.inOut.default} ${props => props.delay}s;
`

export const TableMain = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 1.2rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

export const TableHead = styled.thead``

export const TableScroller = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: calc(100% + 2.4rem);

  &::-webkit-scrollbar {
    display: none;
  }

  ${mq.tabletL} {
    overflow: hidden;
    width: 100%;
  }
`

export const TableScrollerInner = styled.div`
  display: table;
  min-width: 100%;
  padding-right: 2.4rem;
  width: auto;

  ${mq.tabletL} {
    padding-right: 0;
    width: 100%;
  }
`

export const TableElementWrap = styled.div`
  background-color: #9cb6ff;
  border-radius: 1.2rem;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  display: table;
  min-width: 100%;
  position: relative;
  width: auto;

  ${mq.tabletL} {
    overflow: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${TableGradient});
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    border-radius: 1.2rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    opacity: 0.36;
    overflow: hidden;
    will-change: opacity;
    z-index: 1;
  }
`

export const TableBody = styled.tbody``

export const TableRow = styled.tr``

const tableCell = css`
  background-color: ${props => (props.transparent ? `#F8F8FA` : `white`)};
  border-bottom: .1rem solid ${transparentize(0.35, colors.lightGrey)};
  ${clamp('height', 62, 80)}
  ${clamp('padding-left', 22, 32)}
  ${clamp('padding-right', 22, 32)}
  ${clamp('padding-top', 10, 12)}
  ${clamp('padding-bottom', 10, 12)}
  min-width: 15rem;
  text-align: center;

  &:first-child {
    color: ${colors.midGrey};
    left: 0;
    position: sticky;
    text-align: left;
    border-right: .1rem solid ${transparentize(0.35, colors.lightGrey)};
    ${clamp('font-size', 14, 20)}
  }

  ${mq.desk} {
    &:first-child {
      width: 28%;
    }
  }

  &:last-child {
    border-bottom: .1rem solid ${transparentize(0.84, colors.lightGrey)};
    border-right: 0;
    color: ${colors.lightGrey};
    background-color: ${props =>
      props.transparent ? `#F8F8FA` : `transparent`}};
  }

  ${props =>
    props.transparent &&
    `
    font-weight: ${font.primary.weight.semibold};
    column-span: 3;
    letter-spacing: -0.01em;
    text-transform: uppercase;

    &:first-child,
    &:last-child {
      border-right: none;
      color: ${colors.darkGrey};
    }
  `}}
`

export const TableHeading = styled.th`
  ${tableCell}
  ${clamp('font-size', 14, 20)}
  font-weight: ${font.primary.weight.semibold};
  letter-spacing: -0.01em;
  text-transform: uppercase;

  &:first-child {
    color: ${colors.darkGrey};
  }
`

export const TableData = styled.td`
  ${tableCell}
  ${clamp('font-size', 16, 20)}

  &:last-child {
    svg {
      path {
        stroke: ${colors.light};
      }
    }
  }
`

const tableSVG = css`
  margin-left: auto;
  margin-right: auto;
`

export const TableCross = styled.div`
  ${tableSVG}
  height: .8rem;
  width: 0.8rem;

  svg {
    path {
      stroke: ${colors.midGrey};
    }
  }

  ${mq.tabletL} {
    height: 1.3rem;
    width: 1.3rem;
  }
`

export const TableTick = styled.div`
  ${tableSVG}
  height: 1rem;
  width: 1.6rem;

  svg {
    path {
      stroke: ${colors.blue};
    }
  }

  ${mq.tabletL} {
    height: 1.2rem;
    width: 1.7rem;
  }
`

export const TableElement = styled.table`
  border-spacing: 0;
  position: relative;
  width: 100%;
  z-index: 2;

  ${props =>
    props.multiProduct &&
    `
    ${TableHeading} {
      background: ${colors.lightBlue};
      border-bottom: .1rem solid ${transparentize(0.84, colors.lightGrey)};
      border-right: .1rem solid ${transparentize(0.35, colors.lightGrey)};
      color: ${colors.light};

      &:last-child {
        border-right: none;
      }
    }

    ${TableData} {
      background: ${colors.light};
      border-right: .1rem solid ${transparentize(0.35, colors.lightGrey)};
      color: ${colors.darkGrey};

      &:first-child {
        color: ${colors.midGrey};
      }

      &:last-child {
        border-bottom: .1rem solid ${transparentize(0.35, colors.lightGrey)};
        color: ${colors.darkGrey};

        ${TableCross} {
          svg {
            path {
              stroke: ${colors.midGrey};
            }
          }
        }

        ${TableTick} {
          svg {
            path {
              stroke: ${colors.blue};
            }
          }
        }
      }
    }
  `}
`

export const TableProgress = styled.div`
  position: relative;
  width: 100%;
  height: 0.1rem;
  margin-top: 4rem;
  background-color: ${transparentize(0.7, colors.midGrey)};

  &:before {
    background-color: ${colors.blue};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 30%;
  }
`

export const TableProgressBar = styled.span`
  background-color: ${colors.blue};
  display: block;
  left: 25%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0% 0%;
`
