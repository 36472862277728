import styled from 'styled-components'
import { clamp } from '../../../styles/utils/conversion.style'
import { colors } from '../../../styles/vars/colors.style'

export const HeroMain = styled.div`
  position: relative;
`

export const HeroBorder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.1rem;
  background-color: rgba(142, 142, 148, 0.5);
`

export const HeroText = styled.div`
  max-width: 32em;
`

export const HeroLink = styled.div`
  color: ${colors.blue};
  cursor: pointer;
  display: inline-block;

  svg {
    display: block;
    ${clamp('width', 8, 10)}
  }
`
